import React from 'react';
import i18n from "i18next";

const PaymentSuccess = () => {
  return (
    <div className="flex justify-center">
      <div className="flex my-5 border bg-green-300 p-5 rounded items-center">
        <div>{i18n.t('Payment successfully done')}</div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
