import React from 'react';
import i18n from "i18next";

const PaymentFailed = () => {
  return (
    <div className="flex justify-center text-center">
      <div className="flex my-5 border bg-red-300 p-5 rounded items-center">
        <div className={i18n.language==='en' ? 'text-left':'text-right'}>{i18n.t('Payment failed, Try again')}</div>
      </div>
    </div>
  );
};

export default PaymentFailed;
