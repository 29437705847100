import React, {Component, useState} from 'react';
import axios from 'axios';
import PaymentPageTitle from './PaymentComponents/Title';
import Tabs from './PaymentComponents/Tabs';
import WireTransferDetails from './PaymentComponents/WireTransferDetails';
import i18n from 'i18next';
import CartItems from "./CartItems";
import CartForm from "./CartForm";
import CashDetails from "./PaymentComponents/CashDetails";

export default class BookingPayment extends Component {
  state = {
    checkoutId: null,
    loading: true,
    currentTab: 'debitCard',
    pmethod:'credit',
    paynow:false
  };
  locale = i18n.language;
  formUpdated = false;
  componentDidMount() {
    let params = new URLSearchParams(window.location.search);
    const pmethod = params.get('pmethod');
    console.log('componentDidMount',pmethod)
    if (pmethod) {
      let name = localStorage.getItem('name');
      let email = localStorage.getItem('email');
      let phone = localStorage.getItem('phone');

      if (pmethod === 'credit') {
        axios
            .post(`${process.env.REACT_APP_BACKEND_URL}/payment/checkout`, {
              amount: 500,
              name:name,
              phone:phone,
              email:email,
            })
            .then((res) => {
              this.setState(
                  {
                    checkoutId: res.data.id,
                    loading: false,
                    paynow:true
                  },
                  this.renderPaymentForm()
              ); // Call renderPaymentForm once checkoutId is set
            });
      } else {
        //handle not found pmethod

      }
    } else if (params.get('sid')) {
      let name = params.get('name');
      let email = params.get('email');
      let phone = params.get('phone');
      localStorage.setItem('name', name);
      localStorage.setItem('email', email);
      localStorage.setItem('phone', phone);
      this.renderTabbyCard();
    }
  }

  alertDisabledMethods = ()=>{
          alert(i18n.t('Payment Method is not available'));
  }

  componentDidUpdate(prevProps) {
    let params = new URLSearchParams(window.location.search);
    const pmethod = params.get('pmethod');
    console.log('componentDidUpdate',pmethod)

    if (pmethod == 'credit') {
      if (this.formUpdated === false) {
        this.componentDidMount();
        return;
      }
    }
  }

  handleFormSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    const formData = new FormData(event.target);
    axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/payment/result`, formData)
        .then((response) => {
          // Handle the response from the server here
          console.log('Payment Successful', response.data);
        })
        .catch((error) => {
          // Handle errors here
          console.error('Payment Error', error);
        });
  };

  renderPaymentForm = () => {
    const { checkoutId } = this.state;

    if (!checkoutId) return; // Exit if checkoutId is not set
    console.log('this.formUpdated',this.formUpdated)
    if (this.formUpdated) return;

    const existingForm = document.querySelector('.paymentWidgets');

    if (existingForm) {
      console.log('Form already exists');
      return; // Exit if form already exists
    }

    console.log('Loading ');
    const script = document.createElement('script');
    script.src = `https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}`;
    script.async = true;

    document.body.appendChild(script);

    const form = document.createElement('form');
    form.action = `${process.env.REACT_APP_BACKEND_URL}/payment/result`;
    form.setAttribute('class', 'paymentWidgets');
    form.setAttribute('data-brands', 'VISA MASTER AMEX');
    form.addEventListener('submit', this.handleFormSubmit);

    const paymentElement = document.getElementById('payment-component');
    console.log({ paymentElement, form });

    if (paymentElement) {
      paymentElement.appendChild(form);
    }
    this.formUpdated = true;
  };

  renderTabbyCard = ()=> {
    const El = document.getElementById('tabbyCard')?.parentElement;
    if (!El) return;

    const script = document.createElement('script');
    script.src = 'https://checkout.tabby.ai/tabby-card.js';
    // script.async = true;
    El.appendChild(script);
    const scriptCodeEl = document.createElement('script');
    const runScript = `new TabbyCard({
                              selector: '#tabbyCard',
                              currency: 'SAR',
                              lang: 'ar',
                              price: 500,
                              size: 'wide', // required, can be also 'wide', depending on the width.
                              theme: 'default', // required, can be also 'default'.
                              header: true
                            });`;
    // scriptCodeEl.innerHTML = runScript;
    scriptCodeEl.defer = true;
    El.appendChild(scriptCodeEl);
  }
  handlePmethodSelection = (event) => {
    // event.preventDefault();
    // const item = document.querySelector('#pmethodForm input[type="radio"]:checked');
    // if (!item) {
    //   this.setState({pmethod:''});
    //   return;
    // }
    // const pmethod = item?.value;
    // if (!pmethod) return;
    // console.log(pmethod);
    // window.location.href = '/booking-payment?pmethod=' + pmethod;
    // this.componentDidUpdate();
    // window.location.reload();
  }

  render() {
    let params = new URLSearchParams(window.location.search);
    if (params.get('pmethod')) {
      const pmethod = params.get('pmethod');
      return (
          <div className="w-full lg:py-16 container my-5">
            <div className="flex justify-center items-center">
              <div className="main_tabs_box tabs-links card-box main-form stps-form-card payemnts-gateway">
                <div role="form" className="order-steps-section" method="post" action="">
                  <div className="gateway-card">
                    {pmethod === 'credit' ? (
                        <div id="payment-component" className="my-5">
                          {this.state.loading ? <div>Still Loading</div> : null}
                        </div>
                    ) : pmethod === 'wireTransfer' ? (
                        <div id="payment-component" className="my-5">
                          <WireTransferDetails />
                        </div>
                    ) : (
                        <CashDetails />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
      )
    }

    let sid = params.get('sid');
    const paynow = sid ? true : false;
    console.log(sid, paynow);
    return (
        <div className=" w-full lg:py-16 container my-5">
          <div className="flex justify-center items-center">

          </div>
          <div className="flex justify-center items-center">
            <div
                className={`main_tabs_box tabs-links card-box main-form stps-form-card payemnts-gateway ${
                    this.locale === 'en' ? 'locale-en' : 'locale-ar'
                }`} >
              <div role="form" className="order-steps-section" method="post" action="">
                <div className="gateway-card">
                  <div className="title">
                     <PaymentPageTitle />
                  </div>
                  <h5 className="sm-txt"></h5>


                </div>
                <div className="stepForm">
                  <div className="stepForm-row setup-panel">
                    <div className="stepForm-step">
                      <a href="#step-1" className={`btn btn-default btn-circle ${!paynow ? "btn-stp-active tab-done" : ""}`} disabled={!paynow}></a>
                      <div className="clearfix"></div>
                      <h4> {i18n.t('Order Details')} </h4>
                    </div>
                    <div className="stepForm-step">
                      <a href="#step-2" className={`btn btn-default btn-circle ${paynow ? "btn-stp-active tab-done" : ""}`} disabled={paynow}> </a>
                      <div className="clearfix"></div>
                      <h4>{i18n.t('Payment')}</h4>
                    </div>
                  </div>
                </div>


                <div className="steps-content-sec">
                  <div className="setup-content" id="step-1" style={{display: paynow ? 'none':'block'}} >
                    <div className="select-acc">
                      <div className="row justify-content-center">
                        <div className="col-md-12 col-xs-12">
                          <div className="div-card">
                            <h3> {i18n.t('Order Details')}</h3>
                            <div className="prf-info-list">
                              <ul>
                                <li>
                                  <h4>{i18n.t('Appointment Reservation')}</h4>
                                  <h5>500 {i18n.t('SAR')}</h5>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="div-card">
                            <CartForm/>
                          </div>


                          <div className="stp-frm-btn form-btn">
                            <button className="btn main-btn secondary-btn nextBtn" type="button"
                            style={{visibility: "hidden"}}
                            > {i18n.t('Continue')} <i
                                className="fa-solid fa-arrow-left arrow-rt"></i></button>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="setup-content" id="step-2" style={{display: paynow ? 'block':'none'}}>
                    <form role="form" onSubmit={this.handlePmethodSelection} id="pmethodForm" method="get" action="/booking-payment" className="main-form frm-ipt-bg payments-form-opts">
                      <div id="paymentForm">
                        <div className="ptForm form-group">
                          <div className="form-check">
                            <input type="radio" className="form-check-input" id="cardOptioncredit" name="pmethod"
                                   value="credit" required=""/>
                            <label className="form-check-label" htmlFor="cardOptioncredit">
                              <span>{i18n.t('Pay by Credit Cart / Debit Card')}</span>
                              <div className="cards-list-imgs">
                                <img src="/img/mastercard.svg" alt="Master Card"/>
                                <img src="/img/visa.svg" alt="Visa"/>
                              </div>
                            </label>
                          </div>
                          <div className="form-check">
                            <input type="radio" className="form-check-input" id="cardWireTransfer" name="pmethod"
                                   value="wireTransfer" required=""/>
                            <label className="form-check-label" htmlFor="cardWireTransfer">
                              <span>{i18n.t('Pay via Wire Transfer')}</span>
                              <div className="cards-list-imgs">
                              </div>
                            </label>
                          </div>
                          <div className="form-check" onClick={this.alertDisabledMethods}>
                            <input type="radio" className="form-check-input" id="cardOptionmada" disabled name="pmethod"
                                   value="mada" required=""/>
                            <label className="form-check-label" htmlFor="cardOptionmada">
                              <span>{i18n.t('Pay by Mada Card')}</span>
                              <div className="cards-list-imgs">
                                <img src="/img/mada.png" alt="بطاقة مدى"/>
                              </div>
                            </label>
                          </div>
                          <div className="form-check" onClick={this.alertDisabledMethods}>
                            <input type="radio" className="form-check-input" id="cardOptionapplepay" disabled
                                   name="pmethod"
                                   value="applepay" required=""/>
                            <label className="form-check-label" htmlFor="cardOptionapplepay">
                              <span>{i18n.t('Pay by Apple Pay')}</span>
                              <div className="cards-list-imgs">
                                <img src="/img/applepay.png" alt="Apple Pay"/>
                              </div>
                            </label>
                          </div>
                          <div className="form-check" onClick={this.alertDisabledMethods}>
                            <input type="radio" className="form-check-input" id="cardOptiontabby" disabled
                                   name="pmethod"
                                   value="tabby" required=""/>
                            <label className="form-check-label" htmlFor="cardOptiontabby">
                              <span>{i18n.t('Pay in installments via Tabby')}</span>
                              <div className="cards-list-imgs">
                                <img src="/img/tabby.png" alt="Tabby"/>
                              </div>
                            </label>
                            <div id="tabbyCard"></div>
                          </div>
                          <div className="form-check" onClick={this.alertDisabledMethods}>
                            <input type="radio" className="form-check-input" id="cardOptionsadad" disabled
                                   name="pmethod"
                                   value="sadad" required=""/>
                            <label className="form-check-label" htmlFor="cardOptionsadad">
                              <span>{i18n.t('Pay via Sadad')}</span>
                              <div className="cards-list-imgs">
                                <img src="/img/sadad.png" alt="سداد"/>
                              </div>
                            </label>
                          </div>
                          <div style={{display: this.state.pmethod ? 'none' : ''}} className="invalid-feedback">
                            {i18n.t('You must select a payment method.')}
                          </div>
                        </div>
                      </div>

                      <div className="prvc-txt">
                        <div className="icon">
                          <svg style={{padding: "0 7px 3px 7px"}} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                               width="100" height="100"
                               viewBox="0 0 50 50">
                            <path
                                d="M 25 3 C 18.363281 3 13 8.363281 13 15 L 13 20 L 9 20 C 7.355469 20 6 21.355469 6 23 L 6 47 C 6 48.644531 7.355469 50 9 50 L 41 50 C 42.644531 50 44 48.644531 44 47 L 44 23 C 44 21.355469 42.644531 20 41 20 L 37 20 L 37 15 C 37 8.363281 31.636719 3 25 3 Z M 25 5 C 30.566406 5 35 9.433594 35 15 L 35 20 L 15 20 L 15 15 C 15 9.433594 19.433594 5 25 5 Z M 9 22 L 41 22 C 41.554688 22 42 22.445313 42 23 L 42 47 C 42 47.554688 41.554688 48 41 48 L 9 48 C 8.445313 48 8 47.554688 8 47 L 8 23 C 8 22.445313 8.445313 22 9 22 Z M 25 30 C 23.300781 30 22 31.300781 22 33 C 22 33.898438 22.398438 34.6875 23 35.1875 L 23 38 C 23 39.101563 23.898438 40 25 40 C 26.101563 40 27 39.101563 27 38 L 27 35.1875 C 27.601563 34.6875 28 33.898438 28 33 C 28 31.300781 26.699219 30 25 30 Z"></path>
                          </svg>
                        </div>
                        <h5>{i18n.t('We protect your payment information via Two-way encryption to provide security and sanity of your transaction')}</h5>
                      </div>
                      <div className="stp-frm-btn form-btn">
                        <button className="btn main-btn secondary-btn nextBtn" type="submit">{i18n.t('Pay Now')}<i
                            className="fa-solid fa-arrow-left arrow-rt"></i></button>
                      </div>
                    </form>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
    );
  }
}
